module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx"],"defaultLayouts":{},"gatsbyRemarkPlugins":[],"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/home/runner/work/website-happiz/website-happiz","commonmark":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/favicon.jpeg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"f226f7c5c38d1cf3fea5ae0b41017028"},
    },{
      plugin: require('../node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleTagManager":{"trackingId":"AW-10954565631","cookieName":"gatsby-gdpr-google-tagmanager","dataLayerName":"dataLayer"},"facebookPixel":{"pixelId":"485743193148125","cookieName":"gatsby-gdpr-facebook-pixel"},"tikTokPixel":{"pixelId":"CB3HNRJC77U2DVH47SG0","cookieName":"gatsby-gdpr-tiktok-pixel"},"environments":["production","development","staging"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"languages":["fr","en"],"defaultLanguage":"fr","redirect":true,"i18nextOptions":{"fallbackLng":"fr","supportedLngs":["fr","en"],"defaultNS":"translation","interpolation":{"escapeValue":false,"transSupportBasicHtmlNodes":true,"transKeepBasicHtmlNodesFor":["br","strong","i","p","a","u"]},"detection":{"order":["querystring","cookie","localStorage","sessionStorage","navigator","htmlTag"],"caches":["localStorage","cookie"]}}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
